<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">

        <div class="row">

            <div class="col-6">
                <validated-input label="Office Name*" v-model="model.office_name"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-input label="Company Name*" name="Company Name" v-model="model.office_type"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>

            <div class="col-12 mb-2">
                <label>Address*</label>
                <ckeditor class="form-control" v-model="model.address"
                          :editor="editor" :config="editorConfig"/>
<!--                <validated-text-area label="Address*" v-model="model.address"-->
<!--                                     :rules="{required: true, max:400}" :disabled="loading"/>-->
            </div>

            <div class="col-6">
                <validated-input type="number" label="Contact Number" v-model="model.contact_number"
                                 :rules="{required: false, min:10, max:10}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-input type="email" label="Email ID" v-model="model.email_id"
                                 :rules="{required: false}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <file-input class="c-file-input" ref="file" @input="clearFile" label="Location Image"
                            v-model="model.location_image"
                            :disabled="loading">
                    <template #append>
                        <div class="btn-group">
                            <btn v-if="initialData.location_image"
                                 @click="viewFile(initialData.location_image)"
                                 type="button" class="fa fa-eye" size="xs"/>
                        </div>
                    </template>
                </file-input>
            </div>

            <div class="col-6">
                <validated-input label="Toll Free No*" v-model="model.toll_free_no"
                                 :rules="{required: true, max:20}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-input label="Fax*" v-model="model.fax"
                                 :rules="{required: true, max:20}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-input label="Gst In*" v-model="model.gst_in"
                                 :rules="{required: true, max:15}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-input label="Cin*" v-model="model.cin"
                                 :rules="{required: true, max:21}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-checkbox label="Display in Home Page" v-model="model.need_to_display_in_home_page"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>

            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>

            <div class="col-12 text-right">
                <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                     :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';

export default {
    name : 'EditOffice',

    props      : { initialData : { type : Object } },
    components : { ckeditor : CKEditor.component },

    data () {
        return {
            addUrl       : urls.cms.office.addEdit,
            editorConfig : {
                toolbar : {
                    items : [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                table : {
                    contentToolbar : ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language : 'en'
            },
            editor : ClassicEditor
        };
    },

    methods : {
        clearFile (file) {
            this.initialData.location_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Updated Office..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
